
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _errordWuaACUMlnMeta } from "/opt/node_app/app/src/pages/_error.vue?macro=true";
import { default as _kitchen_45sinkYRA2NL3DgiMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/pages/_kitchen-sink.vue?macro=true";
import { default as readyBjyCGxCCHcMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/pages/_probes/nuxt/ready.vue?macro=true";
import { default as _reportsYiAmvjLV6eMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/pages/_reports.vue?macro=true";
import { default as _91_46_46_46slug_9371aQbQVhcZMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/pages/[...slug].vue?macro=true";
export default [
  {
    name: "_error",
    path: "/_error",
    component: () => import("/opt/node_app/app/src/pages/_error.vue")
  },
  {
    name: "_kitchen-sink",
    path: "/_kitchen-sink",
    meta: _kitchen_45sinkYRA2NL3DgiMeta || {},
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/pages/_kitchen-sink.vue")
  },
  {
    name: "_probes-nuxt-ready",
    path: "/_probes/nuxt/ready",
    meta: readyBjyCGxCCHcMeta || {},
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/pages/_probes/nuxt/ready.vue")
  },
  {
    name: "_reports",
    path: "/_reports",
    meta: _reportsYiAmvjLV6eMeta || {},
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/pages/_reports.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_9371aQbQVhcZMeta || {},
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/pages/[...slug].vue")
  }
]