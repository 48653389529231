import payload_plugin_rQVoSEwxzq from "/opt/node_app/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.35.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_sQEL9W8M0z from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_I7gxnvOTTc from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jhclyoIYtJ from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_HIL3bLu7od from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vhVFweRBkC from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_MqlntSAZab from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_VcgqNz9vOd from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_PQBVXSF1Da from "/opt/node_app/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.35.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/node_app/app/.nuxt/components.plugin.mjs";
import prefetch_client_bwPdtBURKy from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_lxMpOb7yIi from "/opt/node_app/app/node_modules/.pnpm/@sentry+nuxt@8.34.0-internal.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelem_wrrawiu6a5rykjwxf4tlsjkhm4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/node_app/app/.nuxt/sentry-client-config.mjs";
import plugin_Jr1CwJz1D1 from "/opt/node_app/app/src/modules/gtm/runtime/plugin.ts";
import basket_client_dC5ss0Zti2 from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/plugins/basket.client.ts";
import dialogs_DFaSeB4G3W from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/plugins/dialogs.ts";
import hydrated_client_ZN7f3Aul7R from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/plugins/hydrated.client.ts";
import middleware_o8I2Khw6Ur from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/plugins/middleware.ts";
import safeHtml_3BQYN9wDmH from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/plugins/safeHtml.ts";
import theme_FC3oCMCanY from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/plugins/theme.ts";
import tooltip_jAibZfTmpw from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/plugins/tooltip.ts";
import logging_82983bmFHh from "/opt/node_app/app/src/plugins/logging.ts";
export default [
  payload_plugin_rQVoSEwxzq,
  revive_payload_client_sQEL9W8M0z,
  unhead_I7gxnvOTTc,
  router_jhclyoIYtJ,
  payload_client_HIL3bLu7od,
  navigation_repaint_client_vhVFweRBkC,
  check_outdated_build_client_MqlntSAZab,
  chunk_reload_client_VcgqNz9vOd,
  plugin_vue3_PQBVXSF1Da,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bwPdtBURKy,
  sentry_client_lxMpOb7yIi,
  sentry_client_config_o34nk2sJbg,
  plugin_Jr1CwJz1D1,
  basket_client_dC5ss0Zti2,
  dialogs_DFaSeB4G3W,
  hydrated_client_ZN7f3Aul7R,
  middleware_o8I2Khw6Ur,
  safeHtml_3BQYN9wDmH,
  theme_FC3oCMCanY,
  tooltip_jAibZfTmpw,
  logging_82983bmFHh
]