import validate from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/pages/runtime/validate.js";
import headless_45global from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/middleware/headless.global.ts";
import manifest_45route_45rule from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.5_db0@0.3.1_encoding@0.1.13_eslint@8.57.1__r6ie5afh5o5dz6z7kn2xjbtvd4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  headless_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  announcements: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/middleware/announcements.ts"),
  auth: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/middleware/auth.ts"),
  basket: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/middleware/basket.ts"),
  headless: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/middleware/headless.ts"),
  "preview-interceptor": () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/middleware/previewInterceptor.ts"),
  redirect: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.1083_db0@0.3.1_encoding@0.1.13_ioredis@5.6.0_magicast@0.3.5_rollup@4.35.0_ty_d5y53pmwkrhcvzaagqdqn454ne/node_modules/@edco/apollo/src/middleware/redirect.ts"),
  chapter: () => import("/opt/node_app/app/src/middleware/chapter.ts")
}