<script lang="ts" setup>
import type { ContentItem } from "@edco/apollo";

const props = defineProps<{
  content: ContentItem;
}>();

const breadcrumbData = await useContentBreadcrumb( props.content );
</script>

<template>
  <div class="container">
    <a-breadcrumb
      :items="breadcrumbData"
      show-home-label>
      <template #home-icon>
        <a-icon
          icon="fa:solid:house-chimney"
          size="0.75rem" />
      </template>
      <template #separator>
        <a-icon
          icon="fa:regular:chevron-right"
          size="0.75rem" />
      </template>
    </a-breadcrumb>
  </div>
</template>

<style lang="scss" scoped>
::v-deep( .breadcrumb__link ) {
  padding: 10px;
}

::v-deep( .breadcrumb__link--home ) {
  padding-left: 0;
}
</style>
