<script setup lang="ts">
const isOpen = defineModel<boolean>( { default: true, required: true } );

const props = withDefaults( defineProps<{
  hideHeader?: boolean;
  disableCloseOnClick?: boolean;
}>(), {
  hideHeader: false,
  disableCloseOnClick: false,
} );

function onClickOutside () {
  if ( !props.disableCloseOnClick ) {
    isOpen.value = false;
  }
}
</script>

<template>
  <HeadlessTransitionRoot
    :show="isOpen"
    as="template"
    enter="on-enter"
    enter-from="on-enter-from"
    enter-to="on-enter-to"
    leave="on-leave"
    leave-from="on-leave-from"
    leave-to="on-leave-to">
    <HeadlessDialog
      class="modal-dialog"
      @close="onClickOutside">
      <div
        class="modal-dialog__backdrop"
        aria-hidden="true" />

      <div
        class="modal-dialog__panel-wrapper">
        <HeadlessDialogPanel class="modal-dialog__panel">
          <HeadlessDialogTitle
            v-if="!hideHeader"
            class="modal-dialog__title">
            <slot name="header" />
          </HeadlessDialogTitle>
          <HeadlessDialogDescription class="modal-dialog__description">
            <slot />
          </HeadlessDialogDescription>

          <div class="modal-dialog__footer">
            <slot name="footer" />
          </div>
        </HeadlessDialogPanel>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<style lang="scss">
.on-enter {
  transition-duration: 300ms;
  transition-timing-function: ease-out;

  &-from {
    opacity: 0;
  }

  &-to {
    opacity: 100;
  }
}

.on-leave {
  transition-duration: 200ms;
  transition-timing-function: ease-in;

  &-from {
    opacity: 100;
  }

  &-to {
    opacity: 0;
  }
}

.modal-dialog {
  --padding: 1rem;

  position: relative;
  z-index: 50;

  &__backdrop {
    position: fixed;
    inset: 0;
    background: rgb( 0 0 0 / 30% );
  }

  &__panel-wrapper {
    position: fixed;
    inset: 0;
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    padding: 20px;

  }

  &__panel {
    width: 100%;
    max-width: 500px;
    background: white;
    border-radius: 5px;
    overflow: hidden;
  }

  & &__title {
    padding: var( --padding );
    margin: 0;
    color: white;
    background: var( --color-primary-500 );
    font-size: 1.125rem;
  }

  &__description {
    padding: var( --padding );
    margin: 0;
  }

  &__footer {
    border-top: 1px solid lightgray;
    padding: var( --padding );
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
}
</style>
